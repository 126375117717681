import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import IndustryCasestudyTabs from "../../molecules/Industry/IndustryCasestudyTabs";
import SlantedHeader from "../../molecules/aboutus/AboutUsHeader";
import IndustrySlider from "../../molecules/Industry/IndustrySlider";
import ContactForm from "../../molecules/ContactForm";
import ImageBanner from "../../components/atoms/banner/imageBanner";
import bgImgIn from "../../images/industryassets/automotive/AutomotiveIn.png";
import bgImgEn from "../../images/industryassets/automotive/AutomotiveEn.png";
import bgImgJa from "../../images/industryassets/automotive/AutomotiveJa.png";
import { useStaticQuery, graphql } from "gatsby";
import IndustryOurClients from "../../molecules/Industry/IndustryOurClients";
import mobileImg from "../../images/industryassets/banners/AutomotiveMobileImg.png";
import tabletImg from "../../images/industryassets/banners/Automotive.jpg";

import bgImg from "../../images/industryassets/automotive.png";
import IndustryNewHeader from "../../molecules/Industry/v2.1/IndustryNewHeader";
import IndustryFullImageHeader from "../../molecules/Industry/v2.1/IndustryFullImageHeader";

const Automotive = () => {
  const data = useStaticQuery(graphql`
    query GetAutomotiveData {
      gcms {
        industryPages(where: { industrPageType: AUTOMOTIVE }) {
          industrPageType
          headerParagraph
          solutionsPageSlides {
            listItems
            slideHeading
            paragraph
            slideImage {
              url
            }
            url
          }
          insightPages {
            image {
              url
            }
            slug
            title
            typeOfInsight
            writtenBy
            shortDescription
          }
          linkedInArticlesIframeUrl
        }
      }
    }
  `);

  return (
    <Layout isNavWhite={true} hideMap hideFAQ>
      <SEO
        pageName={"Automotive Industry"}
        canonicalLink={"https://www.goken-global.com/industry/automotive/"}
        title={"Automotive Industry Excellence - Driving Innovation by Goken"}
        description={
          "Accelerate success in the automotive industry with Goken. Expert solutions in design, engineering, and manufacturing. Click to rev up your business!"
        }
        // meta="Automotive, auto, automotive engineering, automotive engineer, automotive engineering services, automotive design, EV design, electric vehicle design"
        facebookDescription={
          "Goken Automotive Engineering Services Company in USA, India & Japan is present for more than 15 years in the industry, and diversified our services for automotive industry with the evolving changes in the industry​​. Our clients are: Byton, Continental, Ernie Green Industries and more. "
        }
        facebookTitle={
          "Goken | Top Automotive Engineering Services Company in USA, India & Japan"
        }
        keywords={
          "automotive engineering services, automotive design, training for automotive engineers, automation in CATIA"
        }
      ></SEO>
      {data.gcms && (
        <>
          {/* <SlantedHeader
            header="Automotive"
            para={data.gcms.industryPages[0].headerParagraph}
            btnText="Get In Touch"
            btnLink="/contactus/"
            background={"automotive"}
            isColorPrimary={true}
            bgImageClass="bg-image-automotive"
            currentPage="industry Automotive"
            bgImg={bgImg}
            bgImgIn={bgImgIn}
            bgImgEn={bgImgEn}
            bgImgJa={bgImgJa}
            bgImgTablet={tabletImg}
            bgImgMobile={mobileImg}
            children={
              <IndustryOurClients isAutomotive={true}></IndustryOurClients>
            }
          ></SlantedHeader> */}
          {/* <IndustryNewHeader
            header="Automotive"
            para={data.gcms.industryPages[0].headerParagraph}
            btnText="Get In Touch"
            btnLink="/contactus/"
            background={"automotive"}
            isColorPrimary={true}
            bgImageClass="bg-image-automotive"
            currentPage="industry Automotive"
            bgImg={bgImg}
            bgImgIn={bgImgIn}
            bgImgEn={bgImgEn}
            bgImgJa={bgImgJa}
            bgImgTablet={tabletImg}
            bgImgMobile={mobileImg}
          /> */}
          <IndustryFullImageHeader
            bgImgTablet={tabletImg}
            header="AUTOMOTIVE"
            para={data.gcms.industryPages[0].headerParagraph}
          />
          <IndustryOurClients isAutomotive={true}></IndustryOurClients>

          <IndustrySlider
            hideUrl={false}
            SliderItems={data.gcms.industryPages[0].solutionsPageSlides}
            heading="Automotive"
          ></IndustrySlider>
          <IndustryCasestudyTabs
            heading={"OUR WORK IN THE AUTOMOTIVE INDUSTRY"}
            insights={data.gcms.industryPages[0].insightPages}
            linkedinArticlesUrl={
              data.gcms.industryPages[0].linkedInArticlesIframeUrl
            }
          ></IndustryCasestudyTabs>
        </>
      )}
      {/* <ContactForm showForm={true} typeOfForm="General Inquiry"></ContactForm>
       */}
      <ImageBanner
        heading="Learn more about our services in the Automotive industry"
        btnText="Get in Touch"
        link="/contactus/"
      ></ImageBanner>
    </Layout>
  );
};

export default Automotive;
